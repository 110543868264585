var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-tabs flex flex-col max-h-full flex-1"},[_c('div',{staticClass:"dashboard-tabs__header"},[_c('ul',{staticClass:"list"},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"tab cursor-pointer",class:{
          'tab--selected bg-primary text-white-text border-primary':
            _vm.currentTab?.id === tab.id,
          setTabWidth: _vm.isVerificationCheck,
          disableTab:
            _vm.isVerificationCheck && _vm.isTabDisable && tab.label != 'Run Check',
        },on:{"click":function($event){return _vm.onSelect(tab)}}},[_c('div',[_vm._v(_vm._s(tab.label)+" "),(tab.showCount && tab.count > 0)?_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(`( ${tab.count} )`))]):_vm._e()])])}),0),_c('div',{staticClass:"additional-controls"},[_vm._t("default")],2)]),(!_vm.noContent)?_c('div',{staticClass:"main-content mt-2 min-w-full"},[_vm._t("content"),(_vm.$slots['content-' + _vm.selected])?[_vm._t('content-' + _vm.selected)]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }