<!-- Localized -->
<template>
  <div class="dashboard-tabs flex flex-col max-h-full flex-1">
    <div class="dashboard-tabs__header">
      <ul class="list">
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="tab cursor-pointer"
          :class="{
            'tab--selected bg-primary text-white-text border-primary':
              currentTab?.id === tab.id,
            setTabWidth: isVerificationCheck,
            disableTab:
              isVerificationCheck && isTabDisable && tab.label != 'Run Check',
          }"
          @click="onSelect(tab)"
        >
          <div>{{ tab.label }} <span v-if="tab.showCount && tab.count > 0" class="px-2">{{ `( ${tab.count} )` }}</span></div>
        </li>
      </ul>
      <div class="additional-controls">
        <slot />
      </div>
    </div>
    <div v-if="!noContent" class="main-content mt-2 min-w-full">
      <slot name="content" />
      <template v-if="$slots['content-' + selected]">
        <slot :name="'content-' + selected" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs",
  props: {
    tabs: Array,
    selected: [String, Number],
    value: Object,
    isVerificationCheck: {
      type: Boolean,
    },
    isTabDisable: {
      type: Boolean,
    },
    noContent: Boolean,
	showCount: {
		type: Boolean,
		default: false,
	},
	count: {
		type: String,
		default: ""
	}
  },
  computed: {
    currentTab() {
      return this.value || this.tabs.find((el) => el.id === this.selected);
    },
  },
  methods: {
    onSelect(tab) {
      this.$emit("select", tab.id);
      // allow v-model usage
      if (!tab.id !== this.currentTab?.id) this.$emit("input", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-tabs {
  width: 100%;
  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    .list {
      display: flex;
      align-items: center;
      gap: 20px;
      .tab {
        height: 48px;
        // padding: 2rem 2.5rem;
        // min-width: 160px;
        padding: 0 16px;
        // background-color: #efefefb5;
        // color: rgba(40, 40, 40, 0.7);
        // letter-spacing: 0.095em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px 12px 0 0;
        font-size: 14px;
        font-weight: 600;
        border-width: 1px;
        border-style: solid;
        border-bottom: 0;
        &:not(.tab--selected) {
          @apply text-base-content;
          background-color: rgba(0, 0, 51, 0.067);
          border-color: #f0f0f0;
        }
        // &--selected {
        // }
      }
    }
  }
  &__content {
    margin-top: 20px;
  }
  .main-content {
    height: calc(100% - 60px);
  }
}

.setTabWidth {
  width: 118px;
}
.disableTab {
  background: #e1e1e1 !important;
  border: none !important;
  cursor: not-allowed !important;
  color: rgb(192, 192, 192) !important;
}
</style>
